import React, { useEffect, useState } from 'react';
import { FacebookProvider } from 'react-facebook';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { LicenseInfo } from '@mui/x-license-pro';
import airbridge from 'airbridge-web-sdk-loader';
import mixpanel from 'mixpanel-browser';
import { PostHogProvider } from 'posthog-js/react';
import { SWRConfig } from 'swr';
import TiktokPixel from 'tiktok-pixel';

import ProtectedRoute from '@/components/utility/auth/ProtectedRoute';
import ErrorBoundary from '@/components/utility/ErrorBoundary';
import SnackbarComponent from '@/components/utility/microcomponents/Snackbar';
import AccountProvider from '@/contexts/AccountContext';
import SnackbarProvider from '@/contexts/SnackBarContext';
import { initApi, interceptMixpanelRequests } from '@/utility/api';

import CookiePopup from './components/utility/microcomponents/CookiePopup';
import { ExternalFanHubScreenPreviews } from './components/utility/screen-prototypes/ExternalFanHubScreenPreviews';
import AnimationProvider from './contexts/AnimationContext';
import OnboardingProvider from './contexts/OnboardingContext';
import { WalkthroughProvider } from './contexts/WalkthroughContext';
import ActivityPage from './pages/ActivityPage';
import AIGeneratorPage from './pages/AIGeneratorPage';
import CampaignMetaPage from './pages/CampaignMetaPage';
import CampaignPlaylistPitchPage from './pages/CampaignPlaylistPitchPage';
import CampaignsPage from './pages/CampaignsPage';
import CampaignTikTokPage from './pages/CampaignTikTokPage';
import FanHubPage from './pages/FanHubPage';
import HomePage from './pages/HomePage';
import InsightsPage from './pages/InsightsPage';
import MetaCallbackPage from './pages/MetaCallbackPage';
import OnboardingPage from './pages/OnboardingPage';
import PartnerPerksPage from './pages/PartnerPerksPage';
import PaymentConfirmationPage from './pages/PaymentConfirmationPage';
import PlaylistPitchesPage from './pages/PlaylistPitchesPage';
import ProfilePage from './pages/ProfilePage';
import PromotePage from './pages/PromotePage';
import ReleaseCyclePage from './pages/ReleaseCyclePage';
import SettingsPage from './pages/SettingsPage';
import SocialAdsPage from './pages/SocialAdsPage';
import TikTokCallbackPage from './pages/TikTokCallbackPage';
import ToDoPage from './pages/ToDoPage';
import VerifyEmailPage from './pages/VerifyEmailPage';

import './App.scss';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);

ReactPixel.init(import.meta.env.VITE_REACT_PIXEL);
ReactPixel.pageView();
TiktokPixel.init(import.meta.env.VITE_TIKTOK_PIXEL);
TiktokPixel.pageView();

ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS);
hotjar.initialize({ id: import.meta.env.VITE_HOTJAR_ID, sv: 6 });

airbridge.init({
  app: import.meta.env.VITE_AIRBRIDGE_APP_NAME || '',
  webToken: import.meta.env.VITE_AIRBRIDGE_WEB_SDK_TOKEN || '',
  utmParsing: true,
});

initApi(import.meta.env.VITE_API_URL);

mixpanel.init(import.meta.env.VITE_MIXPANEL_ID || '', {
  track_pageview: 'full-url',
});

const App = () => {
  const [cookiePolicyOpen, setCookiePolicyOpen] = useState(false);
  const { pathname, search } = useLocation();

  useEffect(() => {
    interceptMixpanelRequests();
  }, []);

  useEffect(() => {
    if (pathname.includes('/fan-hub-preview')) return setCookiePolicyOpen(false);
    setCookiePolicyOpen(!localStorage.getItem('cookieChoice'));
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const excludedPaths = [
    '/login',
    '/payment-confirmation/meta',
    '/onboarding',
    '/email-verification',
    '/payment-confirmation/tiktok',
    '/payment-confirmation/playlist',
    '/payment-confirmation/subscription',
    '/release-complete',
    '/fan-hub-preview',
  ];

  const hideBefore = excludedPaths.includes(pathname);

  useEffect(() => {
    interceptMixpanelRequests();
  }, []);

  return (
    <ErrorBoundary>
      <SWRConfig
        value={{
          shouldRetryOnError: false,
        }}
      >
        <SnackbarProvider>
          <AnimationProvider>
            <SnackbarComponent />
            {cookiePolicyOpen && <CookiePopup onClose={() => setCookiePolicyOpen(false)} />}
            <AccountProvider>
              <FacebookProvider appId={import.meta.env.VITE_FACEBOOK_APP_ID}>
                <div className={`App ${hideBefore ? 'hide-before' : ''}`}>
                  <PostHogProvider
                    apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
                    options={{
                      api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
                    }}
                  >
                    <WalkthroughProvider>
                      <Routes>
                        <Route
                          path="/login"
                          element={
                            <OnboardingProvider>
                              <OnboardingPage isLogin />
                            </OnboardingProvider>
                          }
                        />
                        <Route
                          path="/sign-up"
                          element={
                            <OnboardingProvider>
                              <OnboardingPage />
                            </OnboardingProvider>
                          }
                        />
                        <Route element={<ProtectedRoute />}>
                          <Route path="/" element={<Navigate to={`/home${search}`} />} />
                          <Route path="/home" element={<HomePage />} />
                          <Route
                            path="/onboarding"
                            element={
                              <OnboardingProvider postSignUp>
                                <OnboardingPage postSignUp />
                              </OnboardingProvider>
                            }
                          />
                          <Route path="/to-do-list" element={<ToDoPage />} />
                          <Route path="/campaigns" element={<CampaignsPage title="PAGE-TITLES.CAMPAIGNS" />} />
                          <Route path="/social-ads" element={<SocialAdsPage />} />
                          <Route path="/playlisting" element={<PlaylistPitchesPage />} />
                          <Route path="/fan-hub" element={<FanHubPage />} />
                          <Route path="/playlisting/playlist-pitch/:slug" element={<CampaignPlaylistPitchPage />} />
                          <Route path="/social-ads/tiktok/:slug" element={<CampaignTikTokPage />} />
                          <Route path="/social-ads/meta/:slug" element={<CampaignMetaPage />} />
                          <Route path="/campaigns/playlist-pitch/:slug" element={<CampaignPlaylistPitchPage />} />
                          <Route path="/campaigns/tiktok/:slug" element={<CampaignTikTokPage />} />
                          <Route path="/campaigns/meta/:slug" element={<CampaignMetaPage />} />
                          <Route path="/insights" element={<InsightsPage />} />
                          <Route path="/release-cycles" element={<ReleaseCyclePage />} />
                          <Route path="/promote" element={<PromotePage />} />
                          <Route path="/settings" element={<SettingsPage />} />
                          <Route path="/profile" element={<ProfilePage />} />
                          <Route path="/activity" element={<ActivityPage />} />
                          <Route path="/lyra" element={<AIGeneratorPage />} />
                          <Route path="/partner-perks" element={<PartnerPerksPage />} />
                          <Route path="/email-verification" element={<VerifyEmailPage />} />
                          <Route path="/tiktok-callback" element={<TikTokCallbackPage />} />
                          <Route path="/meta-callback" element={<MetaCallbackPage />} />
                          <Route
                            path="/payment-confirmation/meta"
                            element={<PaymentConfirmationPage productType={'meta'} />}
                          />
                          <Route
                            path="/payment-confirmation/tiktok"
                            element={<PaymentConfirmationPage productType={'tiktok'} />}
                          />
                          <Route
                            path="/payment-confirmation/playlist"
                            element={<PaymentConfirmationPage productType={'playlist'} />}
                          />
                          <Route
                            path="/payment-confirmation/subscription"
                            element={<PaymentConfirmationPage productType={'subscription'} />}
                          />
                          <Route
                            path="/release-complete"
                            element={<PaymentConfirmationPage productType={'release-cycle'} />}
                          />
                          <Route path="*" element={<Navigate to={`/home${search}`} />} />
                        </Route>
                        <Route path="/fan-hub-preview" element={<ExternalFanHubScreenPreviews />} />
                      </Routes>
                    </WalkthroughProvider>
                  </PostHogProvider>
                </div>
              </FacebookProvider>
            </AccountProvider>
          </AnimationProvider>
        </SnackbarProvider>
      </SWRConfig>
    </ErrorBoundary>
  );
};

export default App;
